<template>
  <div>
    <a class="come-back" @click.prevent="$router.go(-1)"><div></div>Вернуться назад</a>
    <h2 class="title_categories">
      Редактирование информации для кандидата в общественный совет
    </h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createAdmin" ref="createAdmin">
        <div>
            <div class="content-sb">
                <p>Описание</p>
                <ckeditor :editor-url="$root.editorUrl" :config="editorConfig" v-model="dataForm['f_text']"></ckeditor>
            </div>
            <div class="content-sb">
                <p>Подробная информация</p>
                <ckeditor :editor-url="$root.editorUrl" :config="editorConfig" v-model="dataForm['s_text']"></ckeditor>
            </div>
            <div class="content-sb">
                <p>Информация о подаче документов</p>
                <ckeditor :editor-url="$root.editorUrl" :config="editorConfig" v-model="dataForm['t_text']"></ckeditor>
            </div>
          <div v-if="current_f_file !== null" class="content-sb">
            <p>Старый документ "Стандарт деятельности общественного совета":</p>
            <a target="_blank" :href="current_f_file">Скачать документ</a>
          </div>
            <div class="content-sb">
                <p>Документ "Стандарт деятельности общественного совета"</p>
                <input
                    class="input blue"
                    type="file"
                    @input="festFile"
                >
            </div>
          <div v-if="current_s_file !== null" class="content-sb">
            <p>Старый документ "Образец представления":</p>
            <a target="_blank" :href="current_s_file">Скачать документ</a>
          </div>
            <div class="content-sb">
                <p>Документ "Образец представления"</p>
                <input
                    class="input blue"
                    type="file"
                    name="s_file"
                    @input="secondFile"
                >
            </div>

        </div>

        <footer class="content-sb btns-create-form">
          <router-link to="/admin/candidate_public_council" class="btn-white">Отмена</router-link>
          <input type="submit" class="btn-blue" value="Сохранить"/>
        </footer>
    </form>

  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
export default {
  name: 'CandidatePublicCouncilCreate',
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      editorConfig: {
        extraAllowedContent: 'summary details',
        extraPlugins: 'colorbutton',
        colorButton_enableMore: false,
        colorButton_colors: '246CB7,EB494E,1F3245,272727',
        colorButton_enableAutomatic: false,
        current_f_file: '',
        current_s_file: ''
      },
      dataForm: {
        f_text: '',
        s_text: '',
        t_text: '',
        f_file: '',
        s_file: ''
      }
    }
  },
  mounted () {
    this.$store.dispatch('setCandidatePublicCouncil')
  },
  watch: {
    '$store.getters.getCandidatePublicCouncil' () {
      const dataFromServer = this.$store.getters.getCandidatePublicCouncil
      console.log('1111')
      console.log(dataFromServer)
      this.dataForm = {
        f_text: dataFromServer.f_text,
        s_text: dataFromServer.s_text,
        t_text: dataFromServer.t_text,
        f_file: '',
        s_file: ''
      }
      this.current_f_file = dataFromServer.f_url
      this.current_s_file = dataFromServer.s_url
      this.$forceUpdate()
    }
  },
  methods: {
    createAdmin () {
      const formData = new FormData()
      for (const key in this.dataForm) {
        var item = this.dataForm[key]
        if (key === 'f_file') {
          if (item !== '') {
            formData.append(key, item)
          }
        } else if (key === 's_file') {
          if (item !== '') {
            formData.append(key, item)
          }
        } else {
          formData.append(key, item)
        }
      }

      this.$store.dispatch('updateCandidatePCAdmin', formData)
    },
    festFile (event) {
      const file = event.target.files[0]
      var newFile = new File([file], ' ' + file.name, {
        type: file.type
      })
      this.dataForm.f_file = newFile
    },
    secondFile (event) {
      const file = event.target.files[0]
      var newFile = new File([file], ' ' + file.name, {
        type: file.type
      })
      this.dataForm.s_file = newFile
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
.select, .input{
  width: 33.75rem;
}
.content-sb{
  margin-bottom: 1.25rem;
  span{
    color: red;
  }
}
.wrapper-blog{
  margin-top: 1.25rem;
}
.btns-create-form{
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}
</style>
